
import * as _ref_aHRteC5vcmc_ from 'htmx.org';
import { instantiate } from './ktor-cv-web-wasm-js.uninstantiated.mjs';


const exports = (await instantiate({
    'htmx.org': _ref_aHRteC5vcmc_
})).exports;

export const {
    memory,
    _initialize,
    startUnitTests
} = exports;

